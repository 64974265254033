import { types as sdkTypes } from './sdkLoader'

const { UUID } = sdkTypes

export const marketplaceId = new UUID('16c6a4b8-88ee-429b-835a-6725206cd08c')
export const userJoeId = new UUID('3c073fae-6172-4e75-8b92-f560d58cd47c')
export const userJaneId = new UUID('7b98dd96-74c7-4ddc-9f46-38c0f91c4a19')
export const listing1Id = new UUID('c6ff7190-bdf7-47a0-8a2b-e3136e74334f')
export const listing2Id = new UUID('9009efe1-25ec-4ed5-9413-e80c584ff6bf')
export const listing3Id = new UUID('8918693a-7a58-4d46-8324-f7996ef7579b')
export const listing4Id = new UUID('5e1f2086-522c-46f3-87b4-451c6770c833')
export const listing5Id = new UUID('f5130a7f-4b8b-453b-98e5-78e38ad02c3f')
export const listing6Id = new UUID('927a30a2-3a69-4b0d-9c2e-a41744488703')
export const image1Id = new UUID('4617c584-edfd-49e9-be43-2f1d50fb6e35')
export const image2Id = new UUID('948b1926-67f9-402a-a7c7-f8ff3090c249')
