import { CheckboxFieldsType, DropdownFieldsType } from '../featuresFields'
import config from '../../config'
import { createIntl, createIntlCache } from 'react-intl'

import { messagesInLocale } from '../../intl'

const cache = createIntlCache()
const intl = createIntl(
  {
    locale: config.locale,
    messages: messagesInLocale()
  },
  cache
)

export const icebreakerConfig = [
  {
    id: CheckboxFieldsType.icebreakerTypeKey,
    label: 'Type of icerbreaker events',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_icerbreakerType'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'meetings', label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.meetings' }) },
        { key: 'teamBuilding', label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.teamBuilding' }) },
        {
          key: 'networkingEvents',
          label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.networkingEvents' })
        },
        {
          key: 'trainingWorkshops',
          label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.trainingWorkshops' })
        },
        { key: 'socialEvents', label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.socialEvents' }) },
        {
          key: 'teachingClassroom',
          label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.teachingClassroom' })
        },
        {
          key: 'travelExcursions',
          label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.travelExcursions' })
        },
        {
          key: 'workGroupMeetings',
          label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.workGroupMeetings' })
        },
        {
          key: 'educationStudyGroups',
          label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.educationStudyGroups' })
        },
        { key: 'summerCamps', label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.summerCamps' }) },
        { key: 'groupDynamics', label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.groupDynamics' }) },
        {
          key: 'newHiresOrientation',
          label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.newHiresOrientation' })
        },
        {
          key: 'mentoringCoaching',
          label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.mentoringCoaching' })
        },
        {
          key: 'workplaceTeambuilding',
          label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.workplaceTeambuilding' })
        },
        { key: 'sportsEvents', label: intl.formatMessage({ id: 'MarketplaceConfig.icebreakerType.sportsEvents' }) }
      ]
    }
  }

  // {
  //   id: 'stagePlot',
  //   label: 'StagePlot',
  //   type: 'SelectSingleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_stagePlot'],
  //   config: {
  //     // Schema type is enum for SelectSingleFilter
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',
  //     options: [
  //       { key: '5', label: '5' },
  //       { key: '10', label: '10' },
  //       { key: '15', label: '15' },
  //       { key: '20', label: '20' }
  //     ]
  //   }
  // }
]
