import { CheckboxFieldsType } from '../featuresFields'
import config from '../../config'
import { createIntl, createIntlCache } from 'react-intl'

import { messagesInLocale } from '../../intl'

const cache = createIntlCache()
const intl = createIntl(
  {
    locale: config.locale,
    messages: messagesInLocale()
  },
  cache
)

export const graphicDesignerConfig = [
  {
    id: CheckboxFieldsType.graphicDesignerKey,
    label: 'Specialization',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_designerType'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'brandingIdentity',
          label: intl.formatMessage({ id: 'MarketplaceConfig.designerType.brandIdentity' })
        },
        { key: 'userInterface', label: intl.formatMessage({ id: 'MarketplaceConfig.designerType.userInterface' }) },
        { key: 'userExperience', label: intl.formatMessage({ id: 'MarketplaceConfig.designerType.userExperience' }) },
        {
          key: 'illustrationAndDigitalArt',
          label: intl.formatMessage({ id: 'MarketplaceConfig.designerType.illustrationAndDigitalArt' })
        },
        { key: 'webDesign', label: intl.formatMessage({ id: 'MarketplaceConfig.designerType.webDesign' }) },
        {
          key: 'printAndPublication',
          label: intl.formatMessage({ id: 'MarketplaceConfig.designerType.printAndPublication' })
        },
        { key: 'packaging', label: intl.formatMessage({ id: 'MarketplaceConfig.designerType.packaging' }) }
      ]
    }
  },
  {
    id: CheckboxFieldsType.graphicDesignerToolsKey,
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: [''],
    config: {
      searchMode: 'has_all',
      options: [
        { key: 'adobeCreativeSuite', label: 'Adobe Creative Suite' },
        { key: 'sketch', label: 'Sketch' },
        { key: 'figma', label: 'Figma' },
        { key: 'corelDRAW', label: 'CorelDRAW' }
      ]
    }
  }
]
