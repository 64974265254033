import { CheckboxFieldsType } from '../featuresFields'
import config from '../../config'
import { createIntl, createIntlCache } from 'react-intl'

import { messagesInLocale } from '../../intl'

const cache = createIntlCache()
const intl = createIntl(
  {
    locale: config.locale,
    messages: messagesInLocale()
  },
  cache
)

export const keynoteSpeakerConfig = [
  {
    id: CheckboxFieldsType.keynoteSpeakerKey,
    label: 'Type of events',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_speakerType'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'professionalSpeaker',
          label: intl.formatMessage({ id: 'MarketplaceConfig.speakerType.professionalSpeaker' })
        },
        { key: 'keynoteSpeaker', label: intl.formatMessage({ id: 'MarketplaceConfig.speakerType.keynoteSpeaker' }) },
        { key: 'industrySpeaker', label: intl.formatMessage({ id: 'MarketplaceConfig.speakerType.industrySpeaker' }) }
      ]
    }
  }
]
