import React, { useState, Component } from 'react'
import { bool, func, shape, string } from 'prop-types'
import classNames from 'classnames'
import { Form as FinalForm } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { intlShape, injectIntl, FormattedMessage } from '../../../util/reactIntl'
import { findOptionsForSelectFilter } from '../../../util/search'
import { propTypes } from '../../../util/types'
import config from '../../../config'
import * as custom from '../../../marketplace-custom-config.js'

import { Button, FieldCheckboxGroup, FieldSelect, FieldTextInput, Form } from '../../../components'
import { compose } from 'redux'
import css from '../EditListingFeaturesForm.module.css'
import { Skills, Categories } from '../../../util/category'
import { CheckboxFieldsType, DropdownFieldsType } from '../../../util/featuresFields'
import { required, composeValidators } from '../../../util/validators'
import EditListingTitleForm from './EditListingTitleForm'

class EditTechnicalAssistantFeaturesComponent extends Component {
  render() {
    const { filterConfig, intl } = this.props

    const requiredDropdown = intl.formatMessage({
      id: 'EditListingFeaturesForm.requiredDropdown'
    })
    const requiredCheckbox = intl.formatMessage({
      id: 'EditListingFeaturesForm.requiredCheckbox'
    })

    const technicalAssistantKey = CheckboxFieldsType.technicalAssistantTypeKey
    const technicalAssistantOptions = findOptionsForSelectFilter(technicalAssistantKey, filterConfig)
    const technicalAssistantKeyMessage = intl.formatMessage({
      id: 'EditListingFeaturesForm.technicalAssistantKeyMessage'
    })

    const productionAreaKey = CheckboxFieldsType.productionAreaTypeKey
    const productionAreaOptions = findOptionsForSelectFilter(productionAreaKey, filterConfig)
    const productionAreaKeyMessage = intl.formatMessage({
      id: 'EditListingFeaturesForm.productionAreaKeyMessage'
    })

    return (
      <div>
        <div className={css.listingSectionContainer}>
          <h2>
            <FormattedMessage id="EditListingFeaturesForm.technicalAssistantTitle" />
          </h2>
          <FieldCheckboxGroup
            className={css.features}
            id={technicalAssistantKey}
            name={technicalAssistantKey}
            options={technicalAssistantOptions}
            label={technicalAssistantKeyMessage}
            validate={composeValidators(required(requiredCheckbox))}
          />
          <FieldCheckboxGroup
            className={css.features}
            id={productionAreaKey}
            name={productionAreaKey}
            options={productionAreaOptions}
            label={productionAreaKeyMessage}
            validate={composeValidators(required(requiredCheckbox))}
          />
        </div>
        <EditListingTitleForm></EditListingTitleForm>
        {/* <div className={css.listingSectionContainer}>
          <h2>
            <FormattedMessage id="EditListingFeaturesForm.practicalInfo.greeting" />
          </h2>
        </div> */}
      </div>
    )
  }
}
EditTechnicalAssistantFeaturesComponent.defaultProps = {
  filterConfig: custom.filters
}
EditTechnicalAssistantFeaturesComponent.propTypes = {
  filterConfig: propTypes.filterConfig,
  intl: intlShape.isRequired
}

export default injectIntl(EditTechnicalAssistantFeaturesComponent)
