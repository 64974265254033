import React from 'react'
import PropTypes from 'prop-types'
import { intlShape, injectIntl, FormattedMessage } from '../../../util/reactIntl'
import { compose } from 'redux'
import client01 from './amb1.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Ambassadors = (props) => {
  const { intl, history } = props

  const ambassadorsDescription = intl.formatMessage({
    id: 'Teams.ambassadors.description'
  })

  const ambassadors = []

  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="md">
            <div className="section-title text-center mb4 pb2">
              <h1 className="mb4">
                <FormattedMessage id="Teams.ambassadors.title" />
              </h1>
              <p className="text-muted para-desc mb0 mx-auto">
                <FormattedMessage id="Teams.ambassadors.description1" />
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 30,
                    textAlign: 'left'
                  }}>
                  <FontAwesomeIcon fontSize={10} icon="fa-solid fa-circle" />
                  <FormattedMessage id="Teams.ambassadors.point1" />
                </p>
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 30,
                    textAlign: 'left'
                  }}>
                  <FontAwesomeIcon fontSize={10} icon="fa-solid fa-circle" />
                  <FormattedMessage id="Teams.ambassadors.point2" />
                </p>
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 30,
                    textAlign: 'left'
                  }}>
                  <FontAwesomeIcon fontSize={10} icon="fa-solid fa-circle" />
                  <FormattedMessage id="Teams.ambassadors.point3" />
                </p>
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 30,
                    textAlign: 'left'
                  }}>
                  <FontAwesomeIcon fontSize={10} icon="fa-solid fa-circle" />
                  <FormattedMessage id="Teams.ambassadors.point4" />
                </p>
                <p>
                  <FormattedMessage id="Teams.ambassadors.description2" />
                </p>
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {ambassadors?.map((client) => (
            <div className="sm" key={client?.name}>
              <div className="card team team-primary text-center">
                <div className="card-img team-image d-inline-block mx-auto rounded-pill shadow avatar avatar-ex-large overflow-hidden">
                  <a href={client[`profilePage_${process.env.REACT_APP_LANGUAGE}`]}>
                    <img src={client?.image} className="img-fluid" alt="" />
                  </a>
                  {/*end icon*/}
                </div>

                <div className="content">
                  <p className="text-dark h6 mb0 title d-block">{client?.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/*end row*/}
      </div>
      {/*end container*/}
    </section>
  )
}

Ambassadors.defaultProps = {
  rootClassName: null,
  className: null
}

const { string } = PropTypes

Ambassadors.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired
}
export default compose(injectIntl)(Ambassadors)
