import React, { Component } from 'react'
import { intlShape, injectIntl, FormattedMessage } from '../../../util/reactIntl'
import { findOptionsForSelectFilter } from '../../../util/search'
import { propTypes } from '../../../util/types'
import * as custom from '../../../marketplace-custom-config.js'

import { FieldCheckboxGroup } from '../../../components'
import css from '../EditListingFeaturesForm.module.css'
import { CheckboxFieldsType } from '../../../util/featuresFields'
import { required, composeValidators } from '../../../util/validators'
import EditListingTitleForm from './EditListingTitleForm'

class EditKeynoteSpeakerFeaturesComponent extends Component {
  render() {
    const { filterConfig, intl } = this.props
    const requiredCheckbox = intl.formatMessage({
      id: 'EditListingFeaturesForm.requiredCheckbox'
    })

    const keynoteSpeakerTypeKey = CheckboxFieldsType.keynoteSpeakerKey
    const keynoteSpeakerTypeOptions = findOptionsForSelectFilter(keynoteSpeakerTypeKey, filterConfig)
    const keynoteSpeakerTypeKeyMessage = intl.formatMessage({
      id: 'EditListingFeaturesForm.speakerKeyMessage'
    })

    return (
      <div>
        <div className={css.listingSectionContainer}>
          <h2>
            <FormattedMessage id="EditListingFeaturesForm.greeting.icebreaker" />
          </h2>
          <FieldCheckboxGroup
            className={css.features}
            id={keynoteSpeakerTypeKey}
            name={keynoteSpeakerTypeKey}
            options={keynoteSpeakerTypeOptions}
            label={keynoteSpeakerTypeKeyMessage}
            validate={composeValidators(required(requiredCheckbox))}
          />
        </div>
        <EditListingTitleForm />
      </div>
    )
  }
}
EditKeynoteSpeakerFeaturesComponent.defaultProps = {
  filterConfig: custom.filters
}
EditKeynoteSpeakerFeaturesComponent.propTypes = {
  filterConfig: propTypes.filterConfig,
  intl: intlShape.isRequired
}

export default injectIntl(EditKeynoteSpeakerFeaturesComponent)
