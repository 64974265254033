import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl'
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types'
import classNames from 'classnames'
import omit from 'lodash/omit'
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types'
import { formatMoney } from '../../util/currency'
import { parse, stringify } from '../../util/urlHelpers'
import config from '../../config'
import { ModalInMobile, Button } from '../../components'
import { BookingTimeForm } from '../../forms'
import XIcon from '../../assets/xicon.jpeg'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share'

import css from './BookingPanel.module.css'

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023
const TODAY = new Date()

const priceData = (price, intl) => {
  if (price) {
    const formattedPrice = formatMoney(intl, price)
    return { formattedPrice, priceTitle: formattedPrice }
  }
  // if (price) {
  //   return {
  //     formattedPrice: `(${price.currency})`,
  //     priceTitle: `Unsupported currency (${price.currency})`
  //   }
  // }
  else return {}
}

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0)
  } else {
    const { pathname, search, state } = location
    const searchString = `?${stringify({ ...parse(search), book: true })}`
    history.push(`${pathname}${searchString}`, state)
  }
}

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location
  const searchParams = omit(parse(search), 'book')
  const searchString = `?${stringify(searchParams)}`
  history.push(`${pathname}${searchString}`, state)
}

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' }

const BookingPanel = (props) => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError
  } = props
  const [url, setUrl] = useState(null)
  const price = listing.attributes.price
  const timeZone = listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone
  const hasListingState = !!listing.attributes.state
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED
  const showBookingTimeForm = hasListingState && !isClosed
  const showClosedListingHelpText = listing.id && isClosed
  const { formattedPrice, priceTitle } = priceData(price, intl)
  const isBook = !!parse(location.search).book

  useEffect(() => {
    if (window) {
      setUrl(window.location.href)
    }
  }, [])

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null

  const isNightly = unitType === LINE_ITEM_NIGHT
  const isDaily = unitType === LINE_ITEM_DAY

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit'

  const classes = classNames(rootClassName || css.root, className)
  const titleClasses = classNames(titleClassName || css.bookingTitle)

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}>
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
        </div>
        <div className={css.info}>
          <div className={css.bookingHeading}>
            <div className={css.desktopPriceContainer}>
              <div className={css.desktopPriceValue} title={priceTitle}>
                {formattedPrice}
              </div>
              <div className={css.desktopPerUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div>
            </div>
            <div className={css.bookingHeadingContainer}>
              <h2 className={titleClasses}>{title}</h2>
              {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
            </div>
          </div>
          {!location.pathname.includes('order') && (
            <div className={css.shareButtons}>
              <FacebookShareButton url={url}>
                <FacebookIcon round size={25} />
              </FacebookShareButton>
              <LinkedinShareButton url={url}>
                <LinkedinIcon round size={25} />
              </LinkedinShareButton>
              <WhatsappShareButton url={url}>
                <WhatsappIcon round size={25} />
              </WhatsappShareButton>
              <TwitterShareButton url={url}>
                <img src={XIcon} alt="React Logo" width={23} className={css.xicon} />
              </TwitterShareButton>
            </div>
          )}
        </div>

        {showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>

        {showBookingTimeForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}>
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  )
}

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null
}

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired
  }).isRequired,
  location: shape({
    search: string
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired
}

export default compose(withRouter, injectIntl)(BookingPanel)
