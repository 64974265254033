import { CheckboxFieldsType, DropdownFieldsType } from '../featuresFields'
import config from '../../config'
import { createIntl, createIntlCache } from 'react-intl'

import { messagesInLocale } from '../../intl'

const cache = createIntlCache()
const intl = createIntl(
  {
    locale: config.locale,
    messages: messagesInLocale()
  },
  cache
)
export const sharedConfig = [
  // Does the creative has own studio?
  {
    id: DropdownFieldsType.ownStudioKey,
    type: '',
    group: 'secondary',
    queryParamNames: [''],
    config: {
      options: [
        { key: 'yes', label: intl.formatMessage({ id: 'MarketplaceConfig.ownStudioKey.yes' }) },
        { key: 'no', label: intl.formatMessage({ id: 'MarketplaceConfig.ownStudioKey.no' }) }
      ]
    }
  },
  //   Does the creative has Sound and Light experience?
  {
    id: DropdownFieldsType.soundLightExpKey,
    type: '',
    group: 'secondary',
    queryParamNames: [''],
    config: {
      options: [
        { key: 'yes', label: intl.formatMessage({ id: 'MarketplaceConfig.soundLightExpKey.yes' }) },
        { key: 'no', label: intl.formatMessage({ id: 'MarketplaceConfig.soundLightExpKey.no' }) },
        { key: 'maybe', label: intl.formatMessage({ id: 'MarketplaceConfig.soundLightExpKey.maybe' }) }
      ]
    }
  },
  // Does the Creative post-edit their materials?
  {
    id: DropdownFieldsType.editingServiceKey,
    type: '',
    group: 'secondary',
    queryParamNames: [''],
    config: {
      options: [
        { key: 'yes', label: intl.formatMessage({ id: 'MarketplaceConfig.ownStudioKey.yes' }) },
        { key: 'no', label: intl.formatMessage({ id: 'MarketplaceConfig.ownStudioKey.no' }) }
      ]
    }
  },
  {
    id: DropdownFieldsType.constellationKey,
    label: 'Constellation',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_constellation'],
    config: {
      // Schema type is enum for SelectSingleFilter
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      options: [
        { key: 'single', label: 'Single' },
        { key: 'duo', label: 'Duo' },
        { key: 'trio', label: 'Trio' },
        { key: 'quartet', label: 'Quartet' },
        { key: 'group', label: 'Group' }
      ]
    }
  },
  {
    id: CheckboxFieldsType.productionAreaTypeKey,
    label: 'Production Area',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_productionAreaType'],
    config: {
      // Schema type is enum for SelectSingleFilter
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      options: [
        { key: 'film', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.film' }) },
        { key: 'television', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.television' }) },
        { key: 'music', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.music' }) },
        { key: 'videoGame', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.videoGame' }) },
        { key: 'theatre', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.theatre' }) },
        { key: 'web', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.web' }) },
        { key: 'advertising', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.advertising' }) },
        { key: 'corporate', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.corporate' }) },
        { key: 'event', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.event' }) },
        { key: 'news', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.news' }) },
        { key: 'sports', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.sports' }) },
        { key: 'animation', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.animation' }) },
        { key: 'documentary', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.documentary' }) },
        { key: 'educational', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.educational' }) },
        { key: 'museum', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.museum' }) },
        { key: 'radio', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.radio' }) },
        { key: 'podcast', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.podcast' }) },
        { key: 'photography', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.photography' }) },
        { key: 'socialMedia', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.socialMedia' }) },
        {
          key: 'publicRelations',
          label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.publicRelations' })
        },
        { key: 'marketing', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.marketing' }) },
        { key: 'publishing', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.publishing' }) },
        { key: 'fineArts', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.fineArts' }) },
        { key: 'fashion', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.fashion' }) },
        { key: 'travel', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.travel' }) },
        { key: 'culinary', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.culinary' }) },
        {
          key: 'environmental',
          label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.environmental' })
        },
        { key: 'health', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.health' }) },
        { key: 'political', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.political' }) },
        { key: 'nonprofit', label: intl.formatMessage({ id: 'MarketplaceConfig.productionAreaType.nonprofit' }) }
      ]
    }
  }
]
