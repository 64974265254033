import { CheckboxFieldsType, DropdownFieldsType } from '../featuresFields'
import config from '../../config'
import { createIntl, createIntlCache } from 'react-intl'

import { messagesInLocale } from '../../intl'

const cache = createIntlCache()
const intl = createIntl(
  {
    locale: config.locale,
    messages: messagesInLocale()
  },
  cache
)

export const producerConfig = [
  {
    id: CheckboxFieldsType.producerTypeKey,
    label: 'Producer',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_producerType'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'executive', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.executive' }) },
        { key: 'line', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.line' }) },
        { key: 'co', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.co' }) },
        { key: 'associate', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.associate' }) },
        { key: 'segment', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.segment' }) },
        { key: 'field', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.field' }) },
        { key: 'post', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.post' }) },
        { key: 'story', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.story' }) },
        { key: 'development', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.development' }) },
        { key: 'creative', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.creative' }) },
        {
          key: 'productionManager',
          label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.productionManager' })
        },
        {
          key: 'unitProductionManager',
          label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.unitProductionManager' })
        },
        {
          key: 'productionCoordinator',
          label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.productionCoordinator' })
        },
        { key: 'assistant', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.assistant' }) },
        { key: 'casting', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.casting' }) },
        { key: 'music', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.music' }) },
        { key: 'visualEffects', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.visualEffects' }) },
        { key: 'animation', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.animation' }) },
        { key: 'realityTv', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.realityTv' }) },
        { key: 'documentary', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.documentary' }) },
        { key: 'featureFilm', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.featureFilm' }) },
        { key: 'shortFilm', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.shortFilm' }) },
        { key: 'commercial', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.commercial' }) },
        { key: 'web', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.web' }) },
        { key: 'liveEvent', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.liveEvent' }) },
        { key: 'broadcast', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.broadcast' }) },
        { key: 'radio', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.radio' }) },
        { key: 'podcast', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.podcast' }) },
        { key: 'gaming', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.gaming' }) },
        { key: 'app', label: intl.formatMessage({ id: 'MarketplaceConfig.producerType.app' }) }
      ]
    }
  }
]
