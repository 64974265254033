import React, { Component } from 'react'
import { intlShape, injectIntl, FormattedMessage } from '../../../util/reactIntl'
import { findOptionsForSelectFilter } from '../../../util/search'
import { propTypes } from '../../../util/types'
import * as custom from '../../../marketplace-custom-config.js'

import { FieldCheckboxGroup, FieldTextInput, FieldSelect } from '../../../components'
import css from '../EditListingFeaturesForm.module.css'
import { CheckboxFieldsType } from '../../../util/featuresFields'
import { required, composeValidators } from '../../../util/validators'
import EditListingTitleForm from './EditListingTitleForm'

class EditGraphicDesignerFeaturesComponent extends Component {
  render() {
    const { filterConfig, intl } = this.props
    const requiredCheckbox = intl.formatMessage({
      id: 'EditListingFeaturesForm.requiredCheckbox'
    })

    const graphicDesignerTypeKey = CheckboxFieldsType.graphicDesignerKey
    const graphicDesignerTypeOptions = findOptionsForSelectFilter(graphicDesignerTypeKey, filterConfig)
    const graphicDesignerTypeKeyMessage = intl.formatMessage({
      id: 'EditListingFeaturesForm.designerKeyMessage'
    })

    return (
      <div>
        <div className={css.listingSectionContainer}>
          <h2>
            <FormattedMessage id="EditListingFeaturesForm.greeting.icebreaker" />
          </h2>
          <FieldCheckboxGroup
            className={css.features}
            id={graphicDesignerTypeKey}
            name={graphicDesignerTypeKey}
            options={graphicDesignerTypeOptions}
            label={graphicDesignerTypeKeyMessage}
            validate={composeValidators(required(requiredCheckbox))}
          />
        </div>
        <EditListingTitleForm />
        <div className={css.listingSectionContainer}>
          <h2>
            <FormattedMessage id="EditListingFeaturesForm.practicalInfo.greeting" />
          </h2>

          <FieldTextInput
            className={css.features}
            id="tools"
            name="tools"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.designerToolsKeyMessage'
            })}
            placeholder={intl.formatMessage({
              id: 'EditListingFeaturesForm.designerToolsPlaceholder'
            })}
          />

          <FieldTextInput
            id="industries"
            name="industries"
            className={css.features}
            type="text"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.industries'
            })}
            placeholder={intl.formatMessage({
              id: 'EditListingFeaturesForm.industriesPlaceholder'
            })}
          />

          <FieldTextInput
            id="designProcess"
            name="designProcess"
            className={css.features}
            type="textarea"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.designProcess'
            })}
            placeholder={intl.formatMessage({
              id: 'EditListingFeaturesForm.designProcessPlaceholder'
            })}
          />

          <FieldTextInput
            id="designFeedback"
            name="designFeedback"
            className={css.features}
            type="textarea"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.designFeedback'
            })}
            placeholder={intl.formatMessage({
              id: 'EditListingFeaturesForm.designFeedbackPlaceholder'
            })}
          />

          <FieldTextInput
            id="language"
            name="language"
            className={css.features}
            type="text"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.language'
            })}
          />

          <FieldTextInput
            id="fileFormat"
            name="fileFormat"
            className={css.features}
            type="text"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.fileFormat'
            })}
          />

          <FieldSelect
            className={css.features}
            name="fileProduction"
            id="fileProduction"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.fileProduction'
            })}>
            <FormattedMessage id="EditListingFeaturesForm.chooseFromList">
              {(id) => (
                <option disabled value="">
                  {id}
                </option>
              )}
            </FormattedMessage>
            {[
              {
                key: 'yes',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.yes'
                })
              },
              {
                key: 'no',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.no'
                })
              }
            ].map((o) => (
              <option key={o.key} value={o.key}>
                {o.label}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            className={css.features}
            name="brandingGuidelinesWork"
            id="brandingGuidelinesWork"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.brandingGuidelinesWork'
            })}>
            <FormattedMessage id="EditListingFeaturesForm.chooseFromList">
              {(id) => (
                <option disabled value="">
                  {id}
                </option>
              )}
            </FormattedMessage>
            {[
              {
                key: 'yes',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.yes'
                })
              },
              {
                key: 'no',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.no'
                })
              }
            ].map((o) => (
              <option key={o.key} value={o.key}>
                {o.label}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            className={css.features}
            name="satisfactionPolicy"
            id="satisfactionPolicy"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.satisfactionPolicy'
            })}>
            <FormattedMessage id="EditListingFeaturesForm.chooseFromList">
              {(id) => (
                <option disabled value="">
                  {id}
                </option>
              )}
            </FormattedMessage>
            {[
              {
                key: 'yes',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.yes'
                })
              },
              {
                key: 'no',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.no'
                })
              }
            ].map((o) => (
              <option key={o.key} value={o.key}>
                {o.label}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            className={css.features}
            name="nda"
            id="nda"
            label={intl.formatMessage({
              id: 'EditListingFeaturesForm.nda'
            })}>
            <FormattedMessage id="EditListingFeaturesForm.chooseFromList">
              {(id) => (
                <option disabled value="">
                  {id}
                </option>
              )}
            </FormattedMessage>
            {[
              {
                key: 'yes',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.yes'
                })
              },
              {
                key: 'no',
                label: intl.formatMessage({
                  id: 'MarketplaceConfig.songRequest.no'
                })
              }
            ].map((o) => (
              <option key={o.key} value={o.key}>
                {o.label}
              </option>
            ))}
          </FieldSelect>
        </div>
      </div>
    )
  }
}
EditGraphicDesignerFeaturesComponent.defaultProps = {
  filterConfig: custom.filters
}
EditGraphicDesignerFeaturesComponent.propTypes = {
  filterConfig: propTypes.filterConfig,
  intl: intlShape.isRequired
}

export default injectIntl(EditGraphicDesignerFeaturesComponent)
