import React from 'react'
import PropTypes from 'prop-types'
import { useIntercom } from 'react-use-intercom'

const IntercomArticle = (props) => {
  const { articleId } = props
  const { showArticle } = useIntercom()
  const handleShowArticle = () => showArticle(articleId)
  return (
    <div>
      <button onClick={handleShowArticle}>Need Help?</button>
    </div>
  )
}
const { number } = PropTypes
IntercomArticle.propTypes = {
  articleId: number
}

export default IntercomArticle
