import { CheckboxFieldsType, DropdownFieldsType } from '../featuresFields'
import config from '../../config'
import { createIntl, createIntlCache } from 'react-intl'

import { messagesInLocale } from '../../intl'

const cache = createIntlCache()
const intl = createIntl(
  {
    locale: config.locale,
    messages: messagesInLocale()
  },
  cache
)

export const technicalAssistantConfig = [
  {
    id: CheckboxFieldsType.technicalAssistantTypeKey,
    label: 'Technical Assistant',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_technicalAssistantType'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'camera',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.camera' })
        },
        {
          key: 'sound',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.sound' })
        },
        {
          key: 'lighting',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.lighting' })
        },
        {
          key: 'grip',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.grip' })
        },
        {
          key: 'production',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.production' })
        },
        {
          key: 'postProduction',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.postProduction' })
        },
        {
          key: 'visualEffects',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.visualEffects' })
        },
        {
          key: 'setDresser',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.setDresser' })
        },
        {
          key: 'prop',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.prop' })
        },
        {
          key: 'costume',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.costume' })
        },
        {
          key: 'hairMakeup',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.hairMakeup' })
        },
        {
          key: 'specialEffects',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.specialEffects' })
        },
        {
          key: 'artDepartment',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.artDepartment' })
        },
        {
          key: 'location',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.location' })
        },
        {
          key: 'scriptSupervisor',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.scriptSupervisor' })
        },
        {
          key: 'casting',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.casting' })
        },
        {
          key: 'stuntCoordinator',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.stuntCoordinator' })
        },
        {
          key: 'productionOfficeCoordinator',
          label: intl.formatMessage({ id: 'MarketplaceConfig.technicalAssistantType.productionOfficeCoordinator' })
        }
      ]
    }
  }
]
