import { denormalisedResponseEntities } from './data'

export const ShareTribeSdk = (function () {
  let instance

  return {
    queryListings: async function (search, maxItems = 20) {
      if (!instance) {
        throw Error('no instance')
      }
      const sdkResponse = await instance.listings.query({
        keywords: search,
        page: 1,
        perPage: 3
      })

      return denormalisedResponseEntities(sdkResponse)
    },
    createInstance: function (sdk) {
      instance = sdk
    }
  }
})()
