import PropTypes from 'prop-types'
import React from 'react'
import css from './ContactUs.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

const ContactUs = () => {
  return (
    <Link to="/contact-us" className={css.contactUsBox}>
      <FontAwesomeIcon icon="fa-solid fa-message" size="2x" color="white" />
    </Link>
  )
}

ContactUs.propTypes = {}

export default ContactUs
