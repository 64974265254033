import React, { Component } from 'react'
import { intlShape, injectIntl, FormattedMessage } from '../../../util/reactIntl'
import { findOptionsForSelectFilter } from '../../../util/search'
import { propTypes } from '../../../util/types'
import config from '../../../config'
import * as custom from '../../../marketplace-custom-config.js'

import { Button, FieldCheckboxGroup, FieldSelect, FieldTextInput, Alert } from '../../../components'
import { compose } from 'redux'
import css from '../EditListingFeaturesForm.module.css'
import { CheckboxFieldsType, DropdownFieldsType } from '../../../util/featuresFields'
import { required, composeValidators } from '../../../util/validators'
import EditListingTitleForm from './EditListingTitleForm'

class EditIcebreakerFeaturesComponent extends Component {
  render() {
    const { filterConfig, intl } = this.props
    const requiredCheckbox = intl.formatMessage({
      id: 'EditListingFeaturesForm.requiredCheckbox'
    })
    const requiredDropdown = intl.formatMessage({
      id: 'EditListingFeaturesForm.requiredDropdown'
    })

    const icebreakerTypeKey = CheckboxFieldsType.icebreakerTypeKey
    const icebreakerTypeOptions = findOptionsForSelectFilter(icebreakerTypeKey, filterConfig)
    const icebreakerTypeKeyMessage = intl.formatMessage({
      id: 'EditListingFeaturesForm.icebreakerTypeKeyMessage'
    })

    const constellationKey = DropdownFieldsType.constellationKey
    const constellationOptions = findOptionsForSelectFilter(constellationKey, filterConfig)
    const constellationMessage = intl.formatMessage({
      id: 'EditListingFeaturesForm.constellationKeyMessage'
    })

    const technicalRiderMessage = intl.formatMessage({
      id: 'EditListingFeaturesForm.technicalRiderMessage'
    })
    const technicalRiderPlaceholderMessage = intl.formatMessage({
      id: 'EditListingFeaturesForm.technicalRiderPlaceholderMessage'
    })

    const cateringRiderMessage = intl.formatMessage({
      id: 'EditListingFeaturesForm.cateringRiderMessage'
    })
    const cateringRiderPlaceholderMessage = intl.formatMessage({
      id: 'EditListingFeaturesForm.cateringRiderPlaceholderMessage'
    })

    return (
      <div>
        <div className={css.listingSectionContainer}>
          <h2>
            <FormattedMessage id="EditListingFeaturesForm.greeting.icebreaker" />
          </h2>
          <FieldCheckboxGroup
            className={css.features}
            id={icebreakerTypeKey}
            name={icebreakerTypeKey}
            options={icebreakerTypeOptions}
            label={icebreakerTypeKeyMessage}
            validate={composeValidators(required(requiredCheckbox))}
          />

          <FieldSelect
            className={css.features}
            name={constellationKey}
            id={constellationKey}
            label={constellationMessage}
            validate={composeValidators(required(requiredDropdown))}>
            <FormattedMessage id="EditListingFeaturesForm.chooseFromList">
              {(id) => (
                <option disabled value="">
                  {id}
                </option>
              )}
            </FormattedMessage>
            {constellationOptions.map((o) => (
              <option key={o.key} value={o.key}>
                {o.label}
              </option>
            ))}
          </FieldSelect>
        </div>
        <EditListingTitleForm></EditListingTitleForm>
        <div className={css.listingSectionContainer}>
          <FieldTextInput
            id="technicalRider"
            name="technicalRider"
            className={css.features}
            type="textarea"
            label={technicalRiderMessage}
            placeholder={technicalRiderPlaceholderMessage}
          />
          <FieldTextInput
            id="cateringRider"
            name="cateringRider"
            className={css.features}
            type="textarea"
            label={cateringRiderMessage}
            placeholder={cateringRiderPlaceholderMessage}
          />
        </div>
        {/* <EditListingOtherInfoForm></EditListingOtherInfoForm> */}
      </div>
    )
  }
}
EditIcebreakerFeaturesComponent.defaultProps = {
  filterConfig: custom.filters
}
EditIcebreakerFeaturesComponent.propTypes = {
  filterConfig: propTypes.filterConfig,
  intl: intlShape.isRequired
}

export default injectIntl(EditIcebreakerFeaturesComponent)
