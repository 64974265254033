/**
 * This is a wrapper component for different Layouts. Main content should be added to this wrapper.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import css from './LayoutWrapperMain.module.css'

const LayoutWrapperMain = (props) => {
  const { className, rootClassName, children } = props
  const classes = classNames(rootClassName || css.root, className)

  return (
    <div className={classes} role="main">
      {children}
    </div>
  )
}

LayoutWrapperMain.defaultProps = {
  className: null,
  rootClassName: null
}

const { node, string } = PropTypes

LayoutWrapperMain.propTypes = {
  children: node.isRequired,
  className: string,
  rootClassName: string
}

export default LayoutWrapperMain
